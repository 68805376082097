import React from "react";

import "./styles.scss";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";

import { HISTORY_REFERRAL_INVITATION_PAGE_SIZE } from "../constants";
import ElementListHistory from "../elementHistory";

import { ListHistoryProps } from "./types";

const ListHistory = ({
  affiliateBillingEventData,
  onLoadMoreClick,
  remainingCount,
  showLoadMoreButton,
  scrollToTop,
}: ListHistoryProps) =>
  (
    <>
      <Helmet>
        <body className="list-history-container" />
      </Helmet>
      <div className="list-history">
        <div className="list-history__list-card-block">
          {affiliateBillingEventData?.map((element) =>
            (
              <ElementListHistory
                amount={element?.amount}
                clientInfo={element?.referralEmail || element?.referralPhone}
                date={element?.createdAt}
              />
            ))}
          {showLoadMoreButton && (
          <Button
            text={`Показать ещё ${Math.min(remainingCount, HISTORY_REFERRAL_INVITATION_PAGE_SIZE)}`}
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Light}
            onClick={onLoadMoreClick}
          />
          )}
        </div>
        <div className="list-history__buttons-block">
          <Button
            text="Пригласить ещё друзей"
            size={ButtonSizeEnum.Middle}
            onClick={scrollToTop}
          />
          <Button
            text={`Выбрать эксперта для\u00A0консультации`}
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Light}
            onClick={() =>
              navigate("/catalog")}
          />
        </div>
      </div>
    </>
  );

export default ListHistory;
